<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" class="ml-n1 mr-2" color="primary"
                ><v-icon>mdi-close</v-icon></v-btn
            >
            <h3>SEND LINK</h3>
        </v-card-title>
        <v-card-text>
            <p>
                Select a project superintendent who will send you a link where
                you can create a report.
            </p>
            <v-select
                v-model="selectedUser"
                :items="project.superintendents"
                label="User *"
                hide-details
            />
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                color="primary"
                rounded
                :disabled="!selectedUser"
                :loading="loading"
                class="mb-3"
                @click="sendLink"
                >SEND</v-btn
            >
        </v-card-actions>
    </v-card>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'

export default {
    name: 'SendLinkToCreateReports',
    props: {
        project: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        loading: false,
        selectedUser: null,
    }),
    methods: {
        ...mapMutations(['setErrorItems']),
        async sendLink() {
            try {
                this.loading = true
                await API.sendLinkToCreateReport({
                    projectId: this.project.id,
                    superintendentEmail: this.selectedUser,
                    type: this.type,
                })
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
